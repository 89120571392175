import axios from 'axios'
import { joinUrlParts } from 'shared/routes'
import { isProduction } from 'shared/is_production'
import { omit, path } from 'shared/ramda_loader'
import { isAxiosNetworkError } from 'shared/error_handling'
import * as Sentry from 'shared/sentry'

/**
 * README
 * =========================================
 *
 * Module works by exporting an interface of HTTP verbs (get, post, put, delete) that talk to a privately instance of
 * axios. The api acts as a proxy to keep our dependency on axios limited to this file as much as possible.
 *
 * Normally, a root-level file like App.js or application.js is responsible for setting the API instance with any
 * necessary headers (e.g. CSRF token). For the native app, it's common to reset the api instance multiple time as
 * the user logs in (and we store the auth token with the headers) or logs out (and we clear the auth token from
 * the headers).
 *
 * The auth token X-TransparentClassroomToken is one of several ways we can authenticate ourselves with the TC
 * backend (other ways include a session token, the distance dropoff PIN, or an invitation token. Search for
 * `*_authenticatable.rb` files for examples of how we read headers and use it to auth the user with Warden, our
 * auth system.
 *
 * The X-TransparentClassroomToken is also what gives people access via API calls from the CLI. We use this token
 * in the native app, because management of cookies in React Native is a confusing, obtuse nightmare, and most
 * guides out there recommend managing auth with an auth header like this one.
 *
 * Auth for RN is... weird. Both `_tclassroom-boom` session cookie and `X-TransparentClassroomToken` are needed.
 * However, once it's been set and you stop tracking the token and forget
 * about it, the server's knowledge of your identity somehow sticks around, presumably in the session cookie. It's
 * unclear how (if at all possible) to delete a cookie. Attempts to overwrite the `_tclassroom-boom` token in a
 * GET request from axios doesn't work; while you can set other cookies, for some reason that one in particular gets
 * set to the one from the server regardless of what you specify on the client.
 */

// update this to a real IP when developing on an actual mobile device
// const host = '10.0.2.2' // for Android emulator unless you use `adb reverse`
// const host = '192.168.1.72' // for LAN
const host = 'localhost'
const devServerUrl = `http://${host}:3000`

// `window.tc` is undefined for native, just ignore when it fails and use separate logic
export const baseURL = path(['tc', 'env', 'serverUrl'], window) || (
  isProduction() ? (
    'https://www.transparentclassroom.com'
  ) : (
    devServerUrl
    // window.tc.env.assetHost.replace('/webpack', '') // ngrok
  )
)

/**
 * "resets" the api instance to use a new base URL (has a default), clears the TC auth token, and sets any
 * new headers. Merges existing headers into the new config. (This behavior could be extended later if we have
 * need for not merging.)
 */
export const resetApi = (newURL = baseURL, newHeaders = {}) => {
  recordApiReset(newURL, newHeaders)
  const oldHeadersWithoutToken = { ...instance.defaults.headers, 'X-TransparentClassroomToken': '' }
  setInstance(axios.create({ baseURL: newURL, headers: { ...oldHeadersWithoutToken, ...newHeaders } }))
}

function recordApiReset(newURL, newHeaders) {
  if (!window.Sentry) { return }

  window.Sentry.addBreadcrumb({
    category: 'debug',
    data: {
      newURL,
      newHeaders,
    },
    level: 'debug',
  })
}

/**
 * Creates a new instance with the auth token set as a header for the provided school ID. Assumed to only be called
 * from React Native because it's the only place managing auth this way is needed.
 */
export const rememberApiToken = (schoolId, token) => {
  setApiInstanceForReactNativeWithSchoolId(schoolId, { 'X-TransparentClassroomToken': token })
}

/**
 * Sets PIN and token headers for managing authentication on the distance dropoff app. See
 * TransparentClassroomDistanceDropoffPinAuthenticatable for its usage. (Unfortunately the header we set here
 * gets transformed in the Warden strategies, so you can't just search for this key elsewhere in the codebase.)
 */
export const rememberDistanceDropoffPin = (schoolId, pin, ddoToken) => {
  setApiInstanceForReactNativeWithSchoolId(schoolId, {
    'X-TransparentClassroomDistanceDropoffPin': pin,
    'X-TransparentClassroomDistanceDropoffToken': ddoToken,
  })

  addInterceptToRefreshOnNotAuthorized(instance)
}

/**
 * ===============================
 * PRIVATE
 * ===============================
 */

let instance = axios.create() // This can be MUTATED

export const setInstance = (newInstance) => {
  instance = newInstance
}

/**
 * Creates and sets a new api instance, along with interceptors for iOS native silliness.
 * TODO IN THEORY this shouldn't be called??
 */
const setApiInstanceForReactNativeWithSchoolId = (schoolId, headers) => {
  resetApi(joinUrlParts(baseURL, `/s/${schoolId}`), headers)
  addInterceptToRejectFailedNetworkRequestsOnIOS(instance)
}

// we need a different version of axios that doesn't have the csrf token set because it messes up CORS
// see https://github.com/axios/axios/issues/1346, and the solution in
// https://github.com/axios/axios/issues/1346#issuecomment-532463835
// Unfortunately there's not a good way to unit test this...
const externalGet = (url, options) => {
  const externalInstance = axios.create()
  return externalInstance.get(url, options)
}

/**
 * Sometimes iOS sometimes drops the network request but returns a 200 response with data saying "The network
 * connection was lost." Long explanation of what's going on is here:
 * https://github.com/transparentclassroom/TransparentClassroom/pull/1746.
 * The interceptor is basically middleware for responses, checks if it matches the pattern, and flags it as a failure
 * rather than success.
 *
 * MUTATES the axios instance.
 */
export const addInterceptToRejectFailedNetworkRequestsOnIOS = (axiosInstance) => {
  axiosInstance.interceptors.response.use((res) => {
    if (res.data === 'The network connection was lost.' && res.status === 200) {
      console.log(res.status) // eslint-disable-line no-console
      console.log(res.data) // eslint-disable-line no-console
      console.log(JSON.stringify(res.request)) // eslint-disable-line no-console
      console.log(JSON.stringify(omit(['data', '_response'], res))) // eslint-disable-line no-console
      throw new Error('Network request failed on iOS')
    }
    return res
  })
}

export const addInterceptToRefreshOnNotAuthorized = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    successfulReq => successfulReq,
    (err) => {
      if (!isAxiosNetworkError(err) &&
        (err.response.status === 401 || err.response.data.message === '401 Unauthorized')) {
        // force a refresh
        location.reload()
        // return empty promise that never resolves or rejects, so no further actions will be taken on it
        return new Promise(() => {})
      }

      return Promise.reject(err)
    })
}

// single place to put things like logging
const call = (verb, ...args) => {
  if (instance == null) throw new Error('Please login before trying to make a server call')
  // console.log(verb, ...args)
  return (instance[verb](...args).catch((thrown) => {
    if (axios.isCancel(thrown)) {
      Sentry.captureMessage('API cancellation error', thrown);
    } else {
      throw thrown;
    }
  }))
}

/**
 * This is basically the same API object, except that it appends a '.json' to the end of the URL.
 * It was created in addition to the o.g. default export because sometimes (as with crud-muffins) we can only specify
 * a base resource path, and can't just put the .json at the end there (because then we'd get routes like
 * PUT /books.json/42). We should probably consider migrating a bunch of usages over to this object, so we can stop
 * being redundant about specifying the .json format.
 */
export const apiJson = {
  get: (requestUrl, ...args) => call('get', `${requestUrl}.json`, ...args),
  post: (requestUrl, ...args) => call('post', `${requestUrl}.json`, ...args),
  patch: (requestUrl, ...args) => call('patch', `${requestUrl}.json`, ...args),
  put: (requestUrl, ...args) => call('put', `${requestUrl}.json`, ...args),
  delete: (requestUrl, ...args) => call('delete', `${requestUrl}.json`, ...args),
  externalGet,
}

const axiosInterface = {
  instance: () => instance,
  get: (...args) => call('get', ...args),
  post: (...args) => call('post', ...args),
  patch: (...args) => call('patch', ...args),
  put: (...args) => call('put', ...args),
  delete: (...args) => call('delete', ...args),
  externalGet,
}
export default axiosInterface
